<template>
  <Modal
    :heading="'Upload fil'"
    :submit="true"
    :submitName="'Upload'"
    @handle-submit="handleUpload"
    @toggle="toggle"
    ref="modalRef"
  >
    <template v-slot:body>
      <div @dragover.prevent @drop.prevent>
        <input
          id="click_upload"
          type="file"
          multiple
          @change="uploadFile"
          ref="fileInput"
          hidden
        />
        <div class="modal-upload-file__drag-zone" @drop="dragFile">
          <div class="modal-upload-file__icon-upload"><IconUpload /></div>
          <div>
            {{ getDictionaryEntry("modalUploadFiles.labelDragFiles") }}
            <label class="modal-upload-file__click-upload" for="click_upload">{{
              getDictionaryEntry("modalUploadFiles.linkBrowseFiles")
            }}</label>
          </div>
          <div v-if="files.length">
            <ul v-for="file in files" :key="file">
              <li class="modal-upload-file__file-list-item">{{ file.name }}</li>
            </ul>
          </div>
        </div>
        <div>
          <p>{{ getDictionaryEntry("modalUploadFiles.comment") }}</p>
          <Textarea @handle-input="setComment" />
        </div>
      </div>
    </template>
    <template v-slot:trigger>
      <div v-if="isReportIssue" ref="modalUploadFile">
        <Button :secondary="true" :upload="true">
          {{ getDictionaryEntry("modalUploadFiles.buttonTrigger") }}
        </Button>
      </div>
      <div v-if="isInstallation" hidden ref="modalUploadFile">
        <Button>
          {{ getDictionaryEntry("modalUploadFiles.buttonTrigger") }}
        </Button>
      </div>
      <Button v-if="!isReportIssue && !isInstallation" :upload="true">
        {{ getDictionaryEntry("modalUploadFiles.buttonTrigger") }}
      </Button>
    </template>
  </Modal>
</template>

<script>
import Modal from "./Modal.vue";
import Button from "./Button.vue";
import IconUpload from "./icons/IconUpload.vue";
import Textarea from "./Textarea.vue";
import { mapGetters } from "vuex";
import { createNamespacedHelpers } from "vuex";

const location = createNamespacedHelpers("location");
const installation = createNamespacedHelpers("installation");
const report = createNamespacedHelpers("report");

export default {
  name: "ModalUploadFiles",
  components: {
    Modal,
    IconUpload,
    Button,
    Textarea,
  },
  props: {
    isInstallation: {
      type: Boolean,
      default: false,
    },
    isReportIssue: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      files: [],
      comment: null,
    };
  },
  computed: {
    ...installation.mapGetters(["activeInstallationFile"]),
    ...report.mapGetters(["activeReport"]),
    ...mapGetters(["isModalOpen"]),
  },
  methods: {
    ...location.mapActions(["UPLOAD_ACTIVE_LOCATION_FILES"]),
    ...installation.mapActions([
      "UPLOAD_INSTALLATION_FILES",
      "UPDATE_ACTIVE_INSTALLATION_FILE",
    ]),
    ...report.mapActions(["UPLOAD_ISSUE_REPORT_FILES", "GET_ISSUE_REPORT"]),
    toggle(isOpened) {
      if (!isOpened) {
        this.UPDATE_ACTIVE_INSTALLATION_FILE(null);
      }
    },
    handleUpload() {
      var words = ["png", "jpg", "jpeg", "pdf", "doc", "docx"];
      var letters = /^[0-9a-zA-Z]+$/;
      for (var i = 0; i < this.files.length; i++) {
        var fileType = new RegExp(words.join("|")).test(this.files[i].type);
        if (fileType === false) {
          alert("Fil er ikke valid: " + this.files[i].name);
          return;
        }
        if (!letters.test(this.files[i].name)) {
          alert("Fil er ikke valid: " + this.files[i].name);
          return;
        }
      }
      if (this.isInstallation) {
        this.UPLOAD_INSTALLATION_FILES({
          id: this.activeInstallationFile,
          files: this.files,
          comment: this.comment,
        });
      } else if (this.isReportIssue) {
        this.UPLOAD_ISSUE_REPORT_FILES({
          issueId: this.activeReport,
          files: this.files,
        }).then(() => {
          this.GET_ISSUE_REPORT({
            issueId: this.activeReport,
            type: "issue",
          });
        });
      } else {
        this.UPLOAD_ACTIVE_LOCATION_FILES({
          files: this.files,
          comment: this.comment,
        });
      }
    },
    uploadFile(e) {
      const files = e.target.files;
      Array.from(files).forEach((file) => {
        this.files.push(file);
      });
    },
    dragFile(e) {
      const files = e.dataTransfer.files;
      Array.from(files).forEach((file) => {
        this.files.push(file);
      });
    },
    setComment(value) {
      this.comment = value;
    },
  },
  watch: {
    activeInstallationFile: function (newValue) {
      if (newValue) {
        this.files = [];
        this.$refs.fileInput.value = null;
        this.$refs.modalUploadFile.click();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-upload-file__drag-zone {
  border: var(--border--md) dotted var(--color-grey);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 4.6rem 0;
  margin-bottom: 4.2rem;
}

.modal-upload-file__click-upload {
  color: var(--color-blue);

  &:hover {
    cursor: pointer;
  }
}

.modal-upload-file__icon-upload {
  width: 6.3rem;
  color: var(--color-grey--light);
  margin-bottom: 3.2rem;
}

.modal-upload-file__file-list-item {
  margin-top: 1rem;
  font-size: 1.3rem;
  color: var(--color-secondary);
}

.modal-upload-file__description {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 4.3rem;
}

.modal-upload-file__description-label {
  margin-bottom: 1.9rem;
  font-weight: var(--font-weight--semibold);
}
</style>
