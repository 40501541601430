<template>
  <BlockSimple>
    <template v-slot:header>
      <h1 class="notification__header">
        {{ getDictionaryEntry("notifications.header") }}
      </h1>
      <p v-html="getDictionaryEntry('notifications.description.text')"></p>
    </template>
    <template v-slot:body>
      <div style="color: red" v-if="error.length > 0">
        {{ getDictionaryEntry("notifications.emailNotValid.text") }}
      </div>
      <div class="label">
        {{ getDictionaryEntry("notifications.email.text") }}
      </div>
      <div>
        <Input
          autocomplete="false"
          id="email"
          type="email"
          @input="handleEmailInput"
          ref="emailInput"
        />
      </div>
      <br />
      <div style="color: red" v-if="nameError.length > 0">
        {{ getDictionaryEntry("notifications.nameNotValid.text") }}
      </div>
      <div class="label">
        {{ getDictionaryEntry("notifications.name.text") }}
      </div>
      <div>
        <Input autocomplete="false" id="name" @input="handleNameInput" />
      </div>
      <br />
      <span>
        <!-- Modal: all locations overwrite -->
        <Modal ref="modalAllLocationsOverwrite">
          <template v-slot:header>
            <h2>Advarsel</h2>
          </template>
          <template v-slot:body>
            {{
              this.getDictionaryEntry(
                "notifications.warning.allLocations.overwrite"
              )
            }}
            <br />
            <br />
            <Button style="display: inline" @click="addNotificationByModal"
              >Ok</Button
            >
            <Button
              style="display: inline"
              @click="$refs.modalAllLocationsOverwrite.toggleModal()"
              >Annuller</Button
            >
          </template>
        </Modal>

        <!-- Modal error when user tries to add notification while being assigned for all locations          -->
        <Modal ref="modalErrorAlreadyAssigned">
          <template v-slot:header>
            <h2>Fejl</h2>
          </template>
          <template v-slot:body>
            {{ getDictionaryEntry("notifications.alert.locationAlreadyAll") }}
            <Button @click="$refs.modalErrorAlreadyAssigned.toggleModal()"
              >OK</Button
            >
          </template>
        </Modal>

        <Button style="float: right" v-on:click="handleAddNotification">{{
          getDictionaryEntry("notifications.buttonAdd.text")
        }}</Button>
      </span>
      <div class="notification__select">
        <Checkbox
          :checked="checked"
          :checkboxId="'All'"
          @checkbox-change="issueClick"
        />
        <div class="notification__label">
          {{
            getDictionaryEntry("notifications.newComingTasksAllLocations.text")
          }}
        </div>
        <Checkbox
          :checked="completedTasks"
          @checkbox-change="completedClick"
          :checkboxId="'Completed'"
        />
        <div class="notification__label">
          {{ getDictionaryEntry("notifications.completedTasksSelect.text") }}
        </div>
        <Checkbox
          :checked="newTasks"
          :checkboxId="'New'"
          @checkbox-change="newClick"
        />
        <div class="notification__label">
          {{ getDictionaryEntry("notifications.newTasksSelect.text") }}
        </div>
      </div>
      <br />
      <div>
        {{
          getDictionaryEntry("notifications.tableDescriptionThisLocation.text")
        }}
      </div>
      <table class="table" cellpadding="8" cellspacing="8">
        <thead>
          <tr>
            <th>Navn</th>
            <th>E-mail</th>
            <th>Type</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(not, index) in this.notis" :key="'kk' + index">
            <td>{{ not.name }}</td>
            <td>{{ not.email }}</td>
            <td>{{ typeTranslate(not.type) }}</td>
            <td>
              <div @click="removeNotification(not.id)" class="deleteIcon">
                <IconTrash />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <br />
      <div>
        {{
          getDictionaryEntry("notifications.tableDescriptionAllLocation.text")
        }}
      </div>
      <table class="table" cellpadding="8" cellspacing="8">
        <thead>
          <tr>
            <th>Navn</th>
            <th>E-mail</th>
            <th>Type</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(not, index) in this.notisAll" :key="'kk' + index">
            <td>{{ not.name }}</td>
            <td>{{ not.email }}</td>
            <td>{{ typeTranslate(not.type) }}</td>
            <td>
              <div @click="removeNotification(not.id)" class="deleteIcon">
                <IconTrash />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </BlockSimple>
</template>

<script>
import IconTrash from "../components/icons/IconTrash.vue";
import { createNamespacedHelpers } from "vuex";
import BlockSimple from "../components/BlockSimple.vue";
import Input from "../components/Input.vue";
import Button from "../components/Button.vue";
import Checkbox from "../components/Checkbox.vue";
import Modal from "../components/Modal.vue";
const notification = createNamespacedHelpers("notification");
const location = createNamespacedHelpers("location");
export default {
  name: "Notifikation",
  components: {
    BlockSimple,
    Input,
    Button,
    Checkbox,
    IconTrash,
    Modal,
  },
  data() {
    return {
      checked: true,
      name: null,
      email: null,
      allLocations: null,
      code: null,
      error: "",
      nameError: "",
      notis: null,
      notisAll: null,
      completedTasks: true,
      newTasks: true,
    };
  },
  computed: {
    ...location.mapGetters([
      "activeLocation",
      "activeLocationDetails",
      "activeLocationFiles",
    ]),
  },
  beforeMount() {},
  watch: {
    activeLocation: function () {
      this.GET_ACTIVE_LOCATION_DETAILS();
      if (this.activeLocation) {
        this.GET_ACTIVE_LOCATION_DETAILS();
      }
      if (!this.activeLocation) {
        return;
      }
      this.getNotifications();
      this.checked = true;
      this.name = "";
      this.email = "";
    },
  },
  created() {
    if (this.activeLocation) {
      this.GET_ACTIVE_LOCATION_DETAILS();
    }
    if (!this.activeLocation) {
      return;
    }
    this.getNotifications();
    this.checked = true;
    this.name = "";
    this.email = "";
  },
  methods: {
    ...notification.mapActions(["ADD_NOTIFICATIONS"]),
    ...notification.mapActions(["GET_NOTIFICATIONS"]),
    ...notification.mapActions(["DELETE_NOTIFICATIONS"]),
    ...location.mapActions([
      "GET_LOCATION_LIST",
      "GET_ACTIVE_LOCATION_DETAILS",
    ]),
    typeTranslate(type) {
      var translated = "";
      switch (type) {
        case "UpcommingTasks3Week":
          translated = this.getDictionaryEntry("genral.newTasks");
          break;
        case "CompletedTasksDaily":
          translated = this.getDictionaryEntry("genral.completedTask");
          break;
        default:
          break;
      }
      return translated;
    },
    issueClick() {
      this.checked = !this.checked;
    },
    completedClick() {
      this.completedTasks = !this.completedTasks;
    },
    newClick() {
      this.newTasks = !this.newTasks;
    },
    removeNotification(id) {
      this.DELETE_NOTIFICATIONS({
        notificationId: id,
      }).then(() => {
        this.notis = null;
        this.getNotifications();
      });
    },
    getNotifications() {
      if (!this.activeLocation.code) {
        return;
      }
      this.notis = this.GET_NOTIFICATIONS({
        locationReferenceCode: this.activeLocation.code,
      }).then((data) => {
        this.notis = data;
      });

      this.notisAll = this.GET_NOTIFICATIONS({
        locationReferenceCode: "",
      }).then((data) => {
        this.notisAll = data;
      });
    },
    addNotificationByModal() {
      this.addNotification();
      this.$refs.modalAllLocationsOverwrite.toggleModal();
    },

    addNotification() {
      var type = "";
      if (this.newTasks) {
        type = "UpcommingTasks3Week";
        this.ADD_NOTIFICATIONS({
          name: this.name,
          email: this.email,
          locationReferenceCode: this.code,
          culture: "da-DK",
          type: type,
        }).then(() => {
          this.getNotifications();
        });
      }

      if (this.completedTasks) {
        type = "CompletedTasksDaily";
        this.ADD_NOTIFICATIONS({
          name: this.name,
          email: this.email,
          locationReferenceCode: this.code,
          culture: "da-DK",
          type: type,
        }).then(() => {
          this.getNotifications();
        });
      }
      this.$refs.emailInput.clearInput();
      this.name = "";
      this.code = "";
    },
    handleAddNotification() {
      if (!this.validEmail(this.email)) {
        this.error = "STOPPPPP";
        return;
      }
      this.code = this.activeLocation.code;
      if (!this.checked) {
        this.code = "";
        this.$refs.modalAllLocationsOverwrite.toggleModal();
      }
      // Single subscription
      else {
        if (this.notisAll.some((x) => x.email == this.email)) {
          this.$refs.modalErrorAlreadyAssigned.toggleModal();
          return;
        } else {
          this.addNotification();
        }
      }
    },
    handleEmailInput(searchQuery) {
      this.email = searchQuery.target.value;
      if (!this.validEmail(this.email)) {
        this.error = "STOPPPPP";
        return;
      }
      this.error = "";
    },
    handleNameInput(searchQuery) {
      this.name = searchQuery.target.value;
      var letters = /^[a-zA-Z0-9_ ]*$/;
      if (!letters.test(this.name)) {
        this.nameError = "STOP";
        return;
      }
      this.nameError = "";
    },
    validEmail: function (email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
  },
};
</script>

<style lang="scss" scoped>
.deleteIcon {
  color: var(--color-red);
  width: 10px;
  &:hover {
    cursor: pointer;
  }
}
.notification__header {
  font-size: 1.8rem;
}

.notification__select {
  display: flex;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 5.8rem;

  &:first-child {
    margin: 0;
  }

  &:last-child {
    margin-bottom: 5.8rem;
  }
}

.notification__label {
  margin-left: 1.5rem;
  margin-right: 4rem;
}
</style>
